import React from "react";
import { Container, Breadcrumb} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../components/PageWrapper";
import { Section, Title, Post } from "../components/Core";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";


function startabusiness() {
    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Plan Du Site - Retrouvez Toutes Les Pages Du Site BankBank</title>
                <meta name="description" content="
               Liste de toutes les pages du site BankBank" />
        </Helmet>
      	<Header />
      	<Section>
        <Container style={{maxWidth: 1280}}>
          <Breadcrumb style={{marginTop: -50, marginBottom: 50}}>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item active style={{ color: 'rgb(189 190 191)'}}>Plan du site</Breadcrumb.Item>
      	 </Breadcrumb>
        	<Title>Plan du site</Title>
		
			<Post>
      <h2>Pages du site BankBank</h2>
      <p>
<Link to={'/'}>Accueil</Link><br></br>
<Link to={'/banque/'}>Avis Banques</Link><br></br>
<Link to={'/banque-academie/'}>Banque Académie</Link><br></br>
<Link to={'/comparateur/'}>Comparateur De Banques</Link><br></br>
<Link to={'/contactez-nous/'}>Contactez-Nous</Link><br></br>
<Link to={'/compte-courant/'}>Meilleur Compte Courant</Link><br></br>
<Link to={'/banque-en-ligne/'}>Meilleure Banque En Ligne</Link><br></br>
<Link to={'/banque-pro/'}>Meilleure Banque Pro</Link><br></br>
<Link to={'/carte-bancaire/'}>Meilleure Carte Bancaire</Link><br></br>
<Link to={'/neobanque/'}>Meilleure Néobanque</Link><br></br>
<Link to={'/mentions-legales/'}>Mentions Légales</Link><br></br>
<Link to={'/partenaires/'}>Partenaires</Link><br></br>
<Link to={'/plan-du-site/'}>Plan du site</Link><br></br>
<Link to={'/politique-de-confidentialite/'}>Politique de confidentialité</Link><br></br>
<Link to={'/qui-sommes-nous/'}>Qui sommes-nous?</Link><br></br>
</p>
<h2>Avis Sur Toutes Les Banques</h2>
<p>
<Link to={'/banque/avis-eko/'}>Avis EKO</Link><br></br>
<Link to={'/banque/avis-revolut/'}>Avis Revolut</Link><br></br>
<Link to={'/banque/avis-bnp-paribas/'}>Avis BNP Paribas</Link><br></br>
<Link to={'/banque/avis-societe-generale/'}>Avis Société Générale</Link><br></br>
<Link to={'/banque/avis-hsbc/'}>Avis HSBC</Link><br></br>
<Link to={'/banque/avis-lcl/'}>Avis LCL</Link><br></br>
<Link to={'/banque/avis-n26/'}>Avis N26</Link><br></br>
<Link to={'/banque/avis-bforbank/'}>Avis BforBank</Link><br></br>
<Link to={'/banque/avis-hello-bank/'}>Avis HelloBank</Link><br></br>
<Link to={'/banque/avis-fortuneo-banque/'}>Avis Fortuneo Banque</Link><br></br>
<Link to={'/banque/avis-ing/'}>Avis ING</Link><br></br>
<Link to={'/banque/avis-monabanq/'}>Avis Monabanq</Link><br></br>
<Link to={'/banque/avis-boursorama-banque/'}>Avis Boursorama Banque</Link><br></br>
</p>
<h2>Guides pratiques pour mieux choisir sa banque</h2>
<p>
<Link to={'/academie/tarifs-des-banques-les-principaux-frais-bancaires/'}>Tarifs des banques – Les principaux frais bancaires</Link><br></br>
<Link to={'/academie/quelle-banque-en-ligne-choisir/'}>Banques en ligne, quelle banque choisir ?</Link><br></br>
<Link to={'/academie/ouvrir-un-compte-bancaire-en-ligne/'}>Ouvrir un compte bancaire en ligne – comment faire ?</Link><br></br>
<Link to={'/academie/meilleure-banque-qui-choisir/'}>Meilleure banque – Qui choisir ?</Link><br></br>
<Link to={'/academie/les-neo-banques-cest-quoi/'}>Les néo-banques – C’est quoi ?</Link><br></br>
<Link to={'/academie/les-meilleures-banques-en-ligne/'}>Les meilleures banques en ligne</Link><br></br>
<Link to={'/academie/frais-de-tenue-de-compte-bancaire-peut-on-les-eviter/'}>Frais de tenue de compte bancaire – Peut-on les éviter ?</Link><br></br>
<Link to={'/academie/les-banques-et-les-paiements-hors-zone-euro-quels-couts/'}>Les banques et les paiements hors zone euro – quels coûts ?</Link><br></br>
<Link to={'/academie/frais-de-decouvert-des-banques-quelle-est-la-moins-chere/'}>Frais de découvert des banques – quelle est la moins chère ?</Link><br></br>
<Link to={'/academie/erreurs-a-eviter-en-banque-en-ligne/'}>Erreurs à éviter avec une banque en ligne</Link><br></br>
<Link to={'/academie/quelle-banque-en-ligne-choisir/'}>Quelle banque en ligne choisir ?</Link><br></br>
<Link to={'/academie/cotisation-carte-bancaire-quelle-est-la-banque-la-moins-chere/'}>Cotisation carte bancaire – quelle est la banque la moins chère ?</Link><br></br>
<Link to={'/academie/banques-en-ligne-le-comparatif/'}>Banques en ligne – Le comparatif</Link><br></br>
<Link to={'/academie/comparateur-de-banque-en-ligne-trouver-la-moins-chere/'}>Comparateur de banque en ligne – Trouver la moins chère</Link><br></br>
<Link to={'/academie/cloturer-son-compte-bancaire-comment-faire/'}>Clôturer son compte bancaire – comment faire ?</Link><br></br>
<Link to={'/academie/classement-des-banques-francaises-quels-criteres/'}>Classement des banques françaises – quels critères ?</Link><br></br>
<Link to={'/academie/changer-de-banque-comment-faire/'}>Changer de banque – comment faire ?</Link><br></br>
<Link to={'/academie/choisir-sa-banque-quelle-est-la-moins-chere/'}>Choisir ma banque – quelle est la moins chère ?</Link><br></br>
<Link to={'/academie/banques-traditionnelles-quelle-est-la-moins-chere/'}>Banques traditionnelles – quelle est la moins chère ?</Link><br></br>
<Link to={'/academie/banque-a-distance/'}>Banque à distance</Link><br></br></p>
<h2>Banques pour les pros et les entreprises</h2><p>
<Link to={'/banque-pro/comparateur/'}>Comparateur Pro</Link><br></br>
<Link to={'/banque-pro/carte-corporate/'}>Carte Corporate</Link><br></br>
<Link to={'/banque-pro/association/'}>Meilleure Banque Pour Associations</Link><br></br>
<Link to={'/banque-pro/eurl-sarl/'}>Meilleure Banque Pour EURL et SARL</Link><br></br>
<Link to={'/banque-pro/sci/'}>Meilleure Banque Pour SCI</Link><br></br>
<Link to={'/banque-pro/auto-entrepreneurs/'}>Meilleure Banque Pour Auto-Entrepreneurs</Link><br></br>
<Link to={'/banque-pro/sas-sasu/'}>Meilleure Banque Pour SAS et SASU</Link><br></br>
<Link to={'/banque-pro/avis-shine/'}>Avis Shine</Link><br></br>
<Link to={'/banque-pro/avis-manager-one/'}>Avis Manager.one</Link><br></br>
<Link to={'/banque-pro/avis-qonto/'}>Avis Qonto</Link><br></br>
<Link to={'/banque-pro/avis-anytime/'}>Avis AnyTime</Link><br></br>
</p>
			</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default startabusiness
